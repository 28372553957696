@import 'variables.module.scss';

.pop_wrapper {
  position: fixed;
  z-index: $z_pop_tabs;

  &.visible {
    .popped {
      opacity: 1;
      transition: right 0.1s ease-out; // cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.4s;
      box-shadow: 0px 0px 8px 0px #666;
    }

    z-index: $z_pop_tabs +1;

    .tab_wrapper {
      .tab {
        left: 0px;

        &:hover {
          left: 0px;
        }
      }
    }
  }

  .tab_wrapper {
    .tab {
      box-sizing: content-box;
      position: absolute;

      top: 140px;
      right: 275px;
      width: 2rem;

      padding: 6px 8px;

      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      cursor: pointer;
      transition: width 0.1s ease-out;

      display: flex;
      align-items: center;

      &:hover {
        width: 7rem;

        .label {
          opacity: 1;
          transition: opacity 0.4s;
          pointer-events: all;
        }
      }

      .icon {
        color: rgba(255, 255, 255, .95);
      }

      .label {
        padding: 2px 8px;
        color: #fff;
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  .popped {

    &.custom {
      border: none !important;
      background: transparent;
      border-radius: 99999px;

      .header {
        display: none;
      }

      .body {
        background: transparent !important;
      }
    }

    .header {
      color: #fff;
      height: 26px;

      .header_label {
        font-size: 13px;
        font-weight: 400;
        padding: 5px 10px;
      }

      .close {
        cursor: pointer;
        font-size: 16px;
        float: right;
        padding: 5px;

        &:hover {
          color: #eee;
        }
      }
    }

    opacity: 0;
    border: 4px solid;
    border-radius: 4px;
    position: relative;
    transition: right 0.2s ease-out,
    opacity 0.5s;
  }
}