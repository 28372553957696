@import 'variables.module.scss';

$revision_color: $purple;

.hidden {
  visibility: hidden;
  z-index: 0;
}

.mask {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  opacity: 0;
  display: none;
  z-index: -1;
  transition: opacity 0.6s;
}

.visible {
  .mask {
    opacity: 1;
    display: block;
    z-index: $z_pop_tabs + 1;
  }
}

.explorer_wrapper {
  transition: 0.2s ease-in-out;
  color: $light_gray_2;

  .explorer {
    width: 250px;
    overflow: hidden;

    .revisions {
      background: #fff;
      height: 325px;
      overflow-y: auto;
      overflow-x: hidden;

      .revision {
        color: $text_color;
        padding: 11px 10px;

        &.selected {
          background: $light_gray_2;
        }

        &:hover {
          background: $light_gray_2;
          cursor: pointer;
        }

        .button {
          border-radius: 30px;
          padding: 3px 5px;
          position: relative;
          font-size: 9px;
          letter-spacing: 1px;
          font-weight: 400;
          top: 7px;
          background: lighten($revision_color, 5%);
          color: $light_gray_2;
          width: 36px;
          text-align: center;
          float: right;

          &:hover {
            background: lighten($revision_color, 10%);
          }

          &.current {
            background: $light_gray;
            color: #999;
          }
        }
      }

      .created_on {
        font-size: 12px;
      }

      .email {
        font-size: 11px;
      }
    }
  }
}