@import 'variables.module.scss';

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3) rotate(2deg);
  }

  100% {
    transform: scale(1);
  }
}

.node_container {
  display: flex;
  // flex-direction: row;

  padding: $node_padding + px;
  z-index: $z_nodes;
  position: relative;

  .uuid {
    user-select: text;
    position: absolute;
    z-index: 10000;
    white-space: nowrap;
    background: #ffffffdd;
    padding: 3px;
    border-radius: 3px;
    cursor: pointer;
    top: 17px;
    align-content: center;
    font-size: 8px;
    font-family: monospace;
    left: 16px;

    &:hover {
      background: #ffffffff;
    }
  }

  &.ghost {
    opacity: 0.5;
    transition: none;
    z-index: $z_dragging;
  }

  &.nondragged {
    opacity: 0.6;
    transition: opacity 400ms ease;
  }

  &.selected {
    .node {
      box-shadow: 0px 0px 0px 2px $selected !important;
      border: 1px solid $selected;
    }
  }

  &.immutable {
    .node {
      cursor: default;
    }
  }

  .node {
    // transition: box-shadow 50ms ease-in, border 50ms ease-in;
    border: 1px solid $borders;
    border-radius: $curvature;
    // background: #fff;
    padding: 0;
    user-select: none;
    cursor: pointer;
    // align-items: stretch;
    min-width: $node_min_width + px;

    flex-shrink: 0;
    // max-width: $node_max_width + px;
    transition: transform 200ms;

    .save_as {
      display: inline;
    }

    .save_result {
      background: #fff;
      padding: 10px 10px;

      // width: inherit;
      .result_name {
        font-weight: 500;
        display: inline;
      }
    }

    .cropped {
      border: 0px solid green;
      overflow: hidden;
      border-top-left-radius: $curvature - 1px;
      border-top-right-radius: $curvature - 1px;
      // min-width: $node_min_width + px;
      // max-width: $node_max_width + px;
    }

    .exit_table {
      border: 0px solid red;

      .exits {
        box-shadow: 0px -2px 0px -1px $borders;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
      }
    }

    .add {
      position: absolute;
      right: $node_padding / 2 + px;
      bottom: $node_padding / 2 + px;
      visibility: hidden;
      color: #fff;
      text-decoration: none;
      background: $blue;
      border-radius: 50%;
      line-height: 8px;
      font-size: 14px;
    }

    &:hover {
      .add {
        visibility: visible;
      }

      .drag_group {
        visibility: visible;
      }
    }
  }

  &.flow_start {
    .flow_start_message {
      position: absolute;
      width: 100%;
      z-index: 10000;
      color: $selected;
      height: 14px;
      padding-top: 2px;
      font-size: 11px;
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
      top: -14px;
      // animation: pulse 300ms;
    }
  }
}

.active {
  position: absolute;
  background: $active_color;
  border: 1px solid darken($active_color, 5%);
  border-radius: 12px;
  padding: 3px 5px;
  color: #fff;
  font-weight: 500;
  top: -10px;
  left: -10px;
  font-size: 13px;
  min-width: 22px;
  text-align: center;
  cursor: pointer;
}