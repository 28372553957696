@import 'variables.module.scss';

.case_list_container {
  overflow: hidden;
  border-radius: $curvature;
  margin-top: 8px;

  &:focus {
    outline: none;
  }

  &.scrolling {
    margin-top: 12px;
    background: rgb(250, 250, 250);
  }
}

.case_list {
  margin: 4px 0px;
  position: relative;
  overflow-y: auto;
  max-height: 190px;
  padding-bottom: 10px;
  padding-top: 10px;

}

.kase {
  z-index: 2000;

  &:last-child {
    :global {
      .fe-x {
        opacity: 0 !important;
        cursor: default;
      }
    }

    .dnd_icon {
      opacity: 0;
    }
  }
}