@import 'variables.module.scss';

.editor {
  position: relative;
  font-family: 'Roboto', sans-serif;
  color: $text_color; // height: 100vh;
  // height: 100%;
  // width: 100%;
  font-size: 13px;
  line-height: 1.2;
  margin: 0;
  user-select: none;
}

.translating {
  .editor {
    $color_1: rgba(0, 0, 0, 0.02);
    $color_2: rgba(0, 0, 0, 0.01);
    background: repeating-linear-gradient(-60deg,
        $color_1,
        $color_1 3px,
        $color_2 3px,
        $color_2 6px); // background: rgba(200, 200, 200, .3);
  }

  :global {
    svg path {
      stroke: $translation_connector !important;

      &.jtk-arrow {
        fill: $translation_connector !important;
      }
    }
  }

  // svg.jtk-connector path.jtk_arrow {
}

.footer {
  bottom: 0px;
  position: fixed;
  width: 100%;
  height: 52px;
  z-index: $z_footer;
  background: #fff;
  box-shadow: 0px -3px 3px 0px $node_shadow_color;

  .download_button {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.alert_body {
  font-size: 15px;
  line-height: 22px;
  padding: 15px 10px;
}

.alert_modal {
  top: 158px;
  position: absolute;
}

.saving {
  z-index: $z_saving;
  opacity: 1;
  top: 2em;
}