@import 'variables.module.scss';

:global {
  .language-selector {
    z-index: $z_editor_menu;
    user-select: none;
  }
}

.language {
  display: inline;
}

.language_link {
  font-size: 12px;
  display: inline;

  text-decoration: underline;
  color: $blue;
  cursor: pointer;

  &:hover {
    color: darken($blue, 20%);
  }

  &.active {
    text-decoration: none;
    color: $gray;
    cursor: default;
  }
}

.separator {
  display: inline-block;
  font-size: 14px;
  padding: 0px 6px;
}
