@import 'variables.module.scss';

@keyframes fadein {
  from {
    transform: scale(0.2);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.evt {
  clear: both;
  margin-bottom: 8px;
  animation: fadein 0.2s;
}

.msg_text {
  padding: 8px 15px;
}

.ivr_msg,
.send_msg {
  border-radius: 10px;
  margin-right: 24px;
  color: #333;
  overflow: auto;

  .msg_text {
    background: $light_gray;
  }
}

.msg_received {
  text-align: right;
  border-radius: 10px;
  margin-left: 24px;
  color: #fff;
  overflow: auto;

  .msg_text {
    background: #2095fe;
    min-height: 16px;
  }

  &.image_attachment {
    padding: 0;
    border: none;
  }
}

.warning {
  float: inherit;
  color: $yellow;
  font-size: 11px;
  border: none;
  text-align: center;
  padding: 10px 15px;
  border-radius: 11px;
}

.error {
  float: inherit;
  color: $red;
  font-size: 11px;
  border: none;
  text-align: center;
  padding: 10px 15px;
  border-radius: 11px;
}

.info {
  border: none;
  text-align: center;
  color: #999;
  font-size: 11px;
  word-wrap: break-word;
  padding: 0 10px;
}

.email {
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #999;
  }
}

.webhook {
  word-break: break-all;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #999;
  }
}

.video_attachment {
  video {
    width: 100%;
  }
}

.audio_attachment {
  background: #ccc;
  padding: 6px 10px;
  position: relative;
  height: 19px;

  .media_player {
    display: inherit;
  }

  .audio_text {
    letter-spacing: 0.09em;
    position: absolute;
    display: inline-block;
    text-transform: uppercase;
    font-size: 11px;
    color: #777;
    top: 9px;
    left: 30px;
  }
}

.email_details {
  padding: 20px;
  font-size: 14px;

  .to {
    padding-top: 10px;
  }

  .subject {
    padding-top: 10px;
  }

  .body {
    padding-top: 20px;
  }
}

.webhook_details {
  unicode-bidi: embed;
  font-family: 'Roboto Mono', sans-serif;
  font-size: 11px;
  line-height: 13px;
  overflow-y: auto;
  max-height: 500px;
  padding: 16px;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;

  ::first-line {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
  }

  .response {
    padding-top: 20px;
  }
}

.event_viewer {
  pre {
    height: 400px;
    white-space: pre-wrap;
    /* css-3 */
    white-space: -moz-pre-wrap;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    border: none;
    border-radius: none;
    background-color: white;
  }
}

.has_detail {
  cursor: pointer;
}

.classification {
  width: 100%;
  font-size: 90%;
  color: #bbb;

  td {
    padding: 0 5px;
  }

  td:last-child {
    text-align: right;
    font-size: 90%;
  }
}