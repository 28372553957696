@import "variables.module.scss";

.draggable {
  position: absolute;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  transition: left 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    top 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.selected {
  transition: none;
  z-index: $z_dragging;
}
