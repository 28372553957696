@import 'variables.module.scss';

.ele {
  width: 100%;
  position: relative;

  &.border {
    border: 1px solid #e6e6e6;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 10px;
  }

  &:focus {
    outline: none;
  }

  >.error_list {
    position: fixed;
    z-index: 1;
    margin-top: 7px;

    >.error {
      visibility: hidden;
      position: absolute;
      background: #fff;
      border: 1px solid $error_color;
      box-shadow: var(--widget-box-shadow-focused-error) !important;
      border-radius: 5px;
      padding: 3px 3px;
      margin: 0;
      margin-left: 1px;
      font-size: 14px;
      width: 100px;
      text-align: center;
      color: $error_color;
      top: 6px;

      .arrow_up {
        width: 0px;
        height: 0px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid $error_color;
        position: absolute;
        margin-top: -9px;
        margin-left: 6px;
      }

      &.send_msg_error {
        margin-top: -15px;
      }
    }
  }

  &:hover {

    // we could also show errors if an input gains focus
    // down our tree with &:focus-within here
    >.error_list>.error {
      visibility: visible;
    }
  }
}

.label {
  margin-left: 2px;
  margin-bottom: 5px;
  font-weight: 400;
  color: #666;
}

.invalid {
  --color-focus: var(--color-error);
  --color-widget-border: var(--color-error);
  --widget-box-shadow-focused: rgba(255, 99, 71, 0.3) 0px 0px 0px 3px;

  textarea,
  input {
    border: 1px solid $error_color !important;
    box-shadow: 0 0 0 3px rgba(255, 196, 186, 0.5) !important;
    transition: all 0.3s ease-in-out;
    outline: none;

  }
}

.help_text {
  color: #aaa;
  font-size: 12px;
  padding-top: 6px;
  padding-bottom: 10px;
}