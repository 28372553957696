@import "variables.module.scss";

.node {
  .to {
    padding: 3px $action_padding;
    background: $light_gray_2;
    width: 100%;
    font-size: 11px;

    .name {
      font-size: 10px;
    }

    .icon {
      font-size: 9px;
      padding-right: 2px;
    }

    .more {
      line-height: 5px;
      padding-bottom: 5px;
    }
  }

  .message {
    padding: $action_padding;
    width: 100%;
  }
}

.flow {
  padding: $action_padding;
}
