@import 'variables.module.scss';

.context_explorer {
    position: absolute;
    height: 473px;
    background: #555;
    right: 260px;
    width: 400px;
    top: 14px;
    cursor: default;
    box-shadow: inset -10px 0 10px -10px #222;
    border-top-left-radius: $curvature;
    border-bottom-left-radius: $curvature;
    transition: width 200ms cubic-bezier(0.175, 0.885, 0.32, 1.075);
    width: 0px;

    display: flex;
    flex-direction: column;

    &.visible {
        opacity: 1;
        width: 400px;

        .panel {
            opacity: 1;
        }
    }

    .close_button {
        padding: 6px;
        font-size: 12px;
        color: #ddd;
        cursor: pointer;

        &:hover {
            color: #fff;
        }
    }

    .panel {
        padding: 0px 10px;
        padding-top: 10px;
        flex-grow: 1;
        overflow-y: scroll;
        opacity: 0;
        transition: opacity 300ms 200ms ease-in-out;

        .row {
            padding: 2px 0px;
            display: flex;




            .arrow_right {
                color: #ddd;
                padding-right: 5px;
                visibility: hidden;
                cursor: pointer;
                position: relative;
                transition: all 200ms ease-in-out;

                &.has_children {
                    visibility: visible;
                }

                &.open {
                    transform: rotate(90deg);
                    top: 2px;
                    left: -2px;
                }
            }

            .key {
                font-size: 12px;
                color: #ddd;
                display: flex;

                &:hover {
                    .clipboard {
                        visibility: visible;
                    }
                }

                .clipboard {
                    color: #ddd;
                    visibility: hidden;
                    font-size: 13px;
                    padding-left: 5px;
                    cursor: pointer;

                    &:hover {
                        color: #fff;
                    }

                    // margin-right: 3px;
                }


                &.has_children {
                    cursor: pointer;
                    color: #e8b5e8;
                }

                .key_summary {
                    margin-left: 4px;
                    font-size: 10px;
                    line-height: 15px;
                    color: #ddd;
                }

            }

            .colon {
                margin-right: 4px;
                color: #eee;
                line-height: 14px;

            }

            .str_value {
                font-size: 85%;
                color: #ccc;
                text-align: right;
                flex-grow: 1;
            }
        }
    }



    .footer {

        background: #444;
        padding: 4px 12px;
        font-size: 12px;
        border-bottom-left-radius: $curvature;

        .hide_checkbox {
            color: #ddd;
        }

        .help {
            display: none;
            color: #999;

            &.visible {
                display: block;
            }
        }

        .empty_toggle {
            font-size: 15px;
            color: #ddd;

            &:hover {
                color: #fff;
                cursor: pointer;
            }
        }

    }

    .message {
        position: absolute;
        color: #ddd;
        background: #444;
        padding: 18px;
        width: 250px;
        top: 140px;
        left: 60px;
        text-align: center;
        border-radius: $curvature;
        transition: opacity 200ms, margin-top 0s 200ms;
        font-size: 15px;
        opacity: 0;
        margin-top: -2000px;
        z-index: 1;

        .expression {
            color: #e8b5e8;
        }

        &.visible {
            opacity: 1;
            margin-top: 0px;
            transition: opacity 200ms;
        }

    }


}