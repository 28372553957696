@import 'variables.module.scss';

.titlebar {
  padding: 0px;
  width: auto;
  color: #fff;

  font-weight: normal;
  text-align: center;
  border: 0px solid transparent;

  .titletext {
    flex-grow: 1;
  }

  .normal {
    padding: 6px 0px;
    width: 100%;
    display: flex;
  }

  .remove_confirm {
    position: relative;
    margin-top: -28px;
    border: 0px solid $removal_confirmation;
    background: $removal_confirmation;
    padding: 6px 0px;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    z-index: 100;

    .remove_button {
      visibility: visible;
    }
  }

  .remove_button {
    padding-right: 0px;
    visibility: hidden;
    width: 20px;
    right: 0;
    color: rgba(255, 255, 255, 0.8);
  }

  .up_button {
    visibility: hidden;
    padding-left: 5px;
    width: 20px;
    left: 0px;
  }

  &:hover {
    .remove_button {
      visibility: visible;
    }

    .up_button {
      visibility: visible;
    }
  }
}