@import 'variables.module.scss';

.link {
  text-decoration: underline;
  color: $link_color;
  cursor: pointer;
}

.info {
  margin-bottom: 0px;
}

.method {
  margin-right: 10px;
  min-width: 110px;
}

.url {
  flex-grow: 1;
  input {
    height: 36px;
  }
}

.code {
  background: #f3f3f3;
  padding: 10px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-size: 12px;
  font-family: 'Roboto Mono', monospace;
  font-weight: 300;
}

.instructions {
  margin-top: 10px;
}

.headers_title {
  margin-top: 0;
}

.remove_ico {
  color: #ccc;
  border: 0px solid green;
  opacity: 0;
  font-size: 12px;
  line-height: 12px;
  width: 20px;
  position: absolute;
  right: -23px;
  top: 25px;
}

.example {
  unicode-bidi: embed;
  font-size: 12px;
  font-family: 'Roboto Mono', monospace;
  font-weight: 300;
  white-space: pre;
  padding: 4px;
  font-size: 0.9em;
  background: $light_gray_2;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.header {
  input {
    height: 28px;
    line-height: 1.2em;
    font-size: 12px;
    padding: 2px 10px;
    border-color: #ccc;
  }
  padding-top: 8px;
  &:hover {
    .remove_ico {
      cursor: pointer;
      opacity: 1;
    }
  }
}

.header_name {
  width: 170px;
  display: inline-block;
}

.header_value {
  width: 434px;
  display: inline-block;
  margin-left: 10px;
}

.body_form {
  .req_body {
    margin-top: 8px;
    textarea {
      display: inline-block;
      padding: 5px 5px;
      unicode-bidi: embed;
      font-size: 12px !important;
      font-family: 'Roboto Mono', monospace;
      font-weight: 300;
      white-space: pre;
      min-height: 200px;
    }
  }
}
