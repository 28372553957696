@import "variables.module.scss";

@keyframes pulse {
  0% {
    transform: scale(0.2);
  }
  20% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
  }
}

.loading {
  padding: 10px;
}

.loading > div {
  border: 1px solid $light_gray_2;
  display: inline-block;
  animation: pulse 0.9s cubic-bezier(0.3, 0, 0.7, 1) infinite;
}
