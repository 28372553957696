.params {
    width: 100%;


    .param {
        .param_name {
            text-align: right;
            padding-right: 20px;
        }
        .param_input {
            width: 100%;
        }
    }
}