@import 'variables.module.scss';

.random {
  background: $light_gray;
}

.issues {
  margin: 15px 0px 5px 0px;
  background: $light_gray_2;
  font-size: 12px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;

  .header {
    display: flex;
    background: #fff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 10px;
    color: $red;

    .icon {
      display: inline-block;
      vertical-align: middle;
      font-size: 18px;
      margin-right: 8px;
    }

    .title {
      font-size: 13px;
    }

    .summary {
      color: rgba(255, 255, 255, 0.8);
      font-size: 11px;
    }
  }
}

.issue_help {
  cursor: pointer;

  &:hover {
    color: lighten($red, 3%);
    text-decoration: underline;
  }
}

.wait_for_audio,
.wait_for_image,
.wait_for_video,
.wait_for_location,
.wait_for_menu,
.wait_for_digits,
.wait_for_response,
.wait_for_dial {
  background: $dark_blue;
}

.add_contact_groups {
  background: $green;
}

.remove_contact_groups {
  background: $gray;
}

.send_email,
.send_broadcast {
  background: $purple;
}

.msg,
.say_msg,
.send_msg,
.request_optin {
  background: $blue;
}

.open_ticket,
.transfer_airtime,
.enter_flow,
.start_session {
  background: $pink;
}

.set_run_result {
  background: $dark_teal;
}

.add_input_labels,
.add_contact_urn,
.set_contact_name,
.set_contact_field,
.set_contact_language,
.set_contact_status,
.set_contact_channel {
  background: $teal;
}

.play_audio {
  background: $yellow;
}

.missing {
  $color_1: tomato;
  $color_2: lighten(tomato, 3%);

  background-image: repeating-linear-gradient(120deg,
      $color_1,
      $color_1 6px,
      $color_2 6px,
      $color_2 18px) !important;
}

.missing_asset {
  color: $error_color;
}

.call_classifier,
.split_by_random,
.split_by_expression,
.split_by_groups,
.split_by_contact_field,
.split_by_intent,
.split_by_run_result,
.split_by_scheme {
  background: $dark_gray;
}

.call_resthook,
.call_webhook {
  background: $orange;
}

.breaks {
  word-wrap: break-word;
}

.url {
  width: $node_min_width + px;
}

.node_asset {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .node_group {
    padding-right: 5px;
  }

  .node_label {
    padding-right: 5px;
  }
}

.advanced_title {
  font-size: 12px;
}

.alert {
  background: $red;
}