@import 'variables.module.scss';

.proper {
  background: #f3f3f3;
  padding: 2px 5px;
  border-radius: 2px;
}

:global {
  body {
    #editor-container {
      height: 100%;
      width: 100%;
      position: relative;
    }

    .ReactModal__Content {
      overflow: visible !important;
      top: 0px !important;
      background: none !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100vh !important;
    }

    &.ReactModal__Body--open {
      height: 100vh !important;
      overflow-y: hidden !important;
      margin: 0 0 5px !important;
    }

    .ReactModal__Content.ReactModal__Content--after-open {
      margin-top: 0px !important;
    }

    .ReactModal__Overlay {
      background-color: rgba(0, 0, 0, 0.6) !important;
      z-index: $z_modal;
    }

    .ReactModalPortal .ReactModal__Overlay {
      background: rgba(0, 0, 0, 0.15);

      &--after-open {
        opacity: 1;
      }

      &--before-close {
        opacity: 0;
      }
    }

    .ReactModalPortal>div {
      opacity: 0;
    }

    .react-select {
      input {
        font-family: 'Roboto', sans-serif;
        font-weight: 100;
        line-height: 28px;
        margin-left: 3px;
        transition: none !important;
        border: none !important;
        box-shadow: none !important;
      }
    }

    .select-small {
      .react-select {
        input {
          margin-left: 0px;
          line-height: 13px;
          font-size: 13px;
          height: 16px;
        }
      }
    }

    // jsplumb, no text selection while dragging
    .jtk-drag-select * {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .jtk-drag-active {
      &.jtk-endpoint {
        visibility: hidden !important;
      }
    }

    svg.jtk-connector {
      z-index: $z_connectors;

      &.jtk-dragging {
        z-index: $z_dragging;
      }

      path {
        stroke: $connector_color;

        &.jtk-arrow {
          fill: $connector_color;
        }
      }

      &.jtk-hover {
        z-index: $z_connectors_hover;

        path {
          stroke: $connector_hover_color;
          stroke-width: 4px;

          &.jtk-arrow {
            fill: $connector_hover_color;
            stroke-width: 1px;
          }
        }
      }

      &.confirm-delete {
        path {
          stroke: $red;

          &.jtk-arrow {
            fill: $red;
          }
        }
      }

      &.translating {
        path {
          stroke: $translation_connector;
          fill: $translation_connector;

          &.jtk-arrow {
            stroke: $translation_connector;
            fill: $translation_connector;
          }
        }
      }
    }

    // our activity lables
    .jtk-overlay {
      z-index: $z_connectors_hover + 1;
    }

    // source and target endpoints
    .jtk-endpoint {
      width: 16px;
      height: 16px;

      // target endpoint
      &.plumb-endpoint {
        z-index: $z_plumb_arrows;
      }

      // target hovered
      &.plumb-endpoint-hover {
        cursor: pointer;
      }
    }

    .plumb-exit .jtk-connected {
      background: $path_color !important;
      border-bottom: 1px solid $connector_color;
    }

    .jtk-recents {
      z-index: $z_recent_contacts;
    }

    .jtk-activity {
      z-index: $z_activity;
    }
  }

  .clearfix {
    clear: both;
  }

  .emphasize {
    font-weight: 400;
  }
}