@import 'variables.module.scss';

.action {
  position: relative;
  margin: 0px;
  overflow: hidden;

  &:last-child {
    border-bottom-right-radius: $curvature;
    border-bottom-left-radius: $curvature;
  }

  &.has_router {
    &:last-child {
      border-bottom: 1px solid $borders;
    }

    &:last-child {
      border-radius: 0px;
    }
  }

  clear: both;
}

.body {
  box-sizing: content-box;
  line-height: 1.4em;
  word-wrap: break-word;
  width: auto;

  &.add_contact_urn,
  &.add_input_labels,
  &.send_msg,
  &.set_contact_field,
  &.set_contact_name,
  &.call_webhook,
  &.call_resthook,
  &.set_contact_language,
  &.set_contact_status,
  &.set_contact_channel,
  &.add_contact_groups,
  &.remove_contact_groups,
  &.play_audio,
  &.say_msg,
  &.set_run_result,
  &.send_email,
  &.send_broadcast,
  &.transfer_airtime,
  &.open_ticket,
  &.missing,
  &.request_optin,
  &.enter_flow {
    width: $node_min_width - $action_padding * 2;
    padding: $action_padding;
  }

  &.call_classifier {
    padding: $action_padding;
    width: inherit;
  }

  &.send_broadcast,
  &.start_session {
    padding: 0px;
    width: $node_min_width + px;
  }

  &.missing {
    color: tomato;
    cursor: default;
  }
}

.not_localizable {
  .overlay {
    display: block;
  }

  .body {
    background: #fff !important;
  }
}

.immutable {
  .overlay {
    display: block;
  }
}

.translate_from {
  background: $translation_bg;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid darken($translation_bg, 5%);
  margin-bottom: 12px;
}

.translate_to {
  padding: 5px;
  font-weight: 400;
}

.translating {
  .body {
    background: $translation_bg;
  }
}

.missing_localization {
  .body {
    color: $translation_missing;
  }
}

.localized {
  .body {
    background: #fff;
  }
}

.overlay {
  cursor: default;
  position: absolute;
  display: none;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  $color_1: rgba(0, 0, 0, 0.12);
  $color_2: rgba(0, 0, 0, 0.1);
}

.message {
  --textarea-height: 8em;
}