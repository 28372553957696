@import "variables.module.scss";

.helpText {
  color: #999;
  font-size: 85%;
  margin-left: 0.25em;
  margin-top: 0.5em;
  margin-bottom: 1em;
}

.checkbox {
  margin-top: 20px;
}