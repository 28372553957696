@import 'variables.module.scss';

.recipients {
  margin-bottom: 15px;
}

.node {
  .to {
    padding: 3px $action_padding;
    background: $light_gray_2;
    font-size: 12px;

    .name {
      font-size: 10px;
    }

    .icon {
      font-size: 9px;
      padding-right: 2px;
    }

    .more {
      line-height: 5px;
      padding-bottom: 5px;
    }
  }

  .message {
    background: #fff;
    padding: $action_padding;

    .line {
      display: block;
      margin-top: 3px;
      min-height: 11px;
    }
  }
}

.attachment {
  margin-top: 10px;
  padding-left: 5px;
  color: $gray;
  transform: rotate(0deg);
  display: inline-block;
}