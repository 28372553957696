@import 'variables.module.scss';

.hidden {
  visibility: hidden;
  z-index: 0;
}

.message {
  padding: 12px 12px;

  .header {
    display: inline-block;
  }

  &:hover {
    background: $light_gray_2;
    cursor: pointer;
  }
}

.translations_wrapper {
  background-color: #fff;
  width: 250px;
  height: 325px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 8px;
  border-radius: 4px;
  transition: all 500ms ease-in-out;
  opacity: 1;
  &.complete {
    opacity: 0;
    padding: 0px;
    height: 0px;
  }
}

.language {
  padding: 6px 10px;
  color: #fff;
  font-weight: 400;
  background: $red;
}

.options {
  background: #777;
  color: $light_gray_2;
  padding-top: 4px;

  &.options_visible {
    .filters {
      height: 35px;
      padding: 6px;
      opacity: 1;
      padding-bottom: 0px;
      .pct_complete {
        opacity: 1;
      }
    }

    .header {
      .toggle {
        transform: rotate(0deg);
      }
    }
  }

  &.change_visible {
    .changeLanguage {
      height: 20px;
      padding: 6px;
      opacity: 1;
    }
  }

  &:hover {
    .header {
      .progress_bar {
        .filled {
          background: lighten($blue, 3%);
        }
        &.hundredpct {
          background: lighten($lime, 3%);
        }
        border: solid 1px rgba(100, 100, 100, 0.7);
      }
      .toggle {
        color: $light_gray_2;
      }
    }
  }

  .header {
    display: flex;
    cursor: pointer;

    .toggle {
      padding: 3px 4px;
      font-size: 9px;
      transition: all 200ms ease-in-out;
      color: darken($light_gray_2, 30%);
      transform: rotate(180deg);
    }

    .progress_bar {
      flex-grow: 1;
      border: solid 1px rgba(100, 100, 100, 0.5);
      border-radius: 3px;
      overflow: hidden;
      box-shadow: inset 0px 0px 2px 2px #727272;

      .filled {
        height: 14px;
        background: $blue;
        box-shadow: 1px 0px 8px 4px #727272;
        transition: width 500ms ease-in-out, background 500ms ease-in-out;

        &.hundredpct {
          background: $lime;
        }
      }
    }
  }

  .filters {
    height: 0px;
    overflow: hidden;
    transition: height 200ms ease-in-out;
    opacity: 0;
    font-size: 12px;
    display: flex;
    flex-flow: wrap;

    .pct_complete {
      font-size: 18px;
      align-self: center;
      margin-top: -6px;
      padding: 0px 4px;
      opacity: 0;
      cursor: pointer;
      transition: opacity 600ms ease-in-out;
    }
    .checkboxes {
      flex-grow: 1;
      > div:last-child {
        margin-top: 4px;
      }
    }
  }

  .changeLanguage {
    height: 0px;
    opacity: 0;

    button {
      background: #777;
      color: $light_gray_2;
      border: solid 1px $light_gray_2;
      width: 100%;
    }
  }
}

.translate_block {
  // padding: 8px 0px;
  margin-bottom: 0px;

  .item {
    margin: 4px 0px;
  }

  .text {
    font-size: inherit;
  }

  .from_text,
  .to_text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .needs_translation {
    // padding: 0px 23px;

    margin: 0px 8px;
    margin-bottom: 8px;

    padding: 8px;
    background: $translation_bg;
    border-radius: 6px;

    .type_name {
      font-size: 12px;
    }

    &:hover {
      box-shadow: 0px 0px 6px 1px rgb(243, 243, 243);
      background: darken($translation_bg, 3%);
      cursor: pointer;
    }

    .from_text {
      font-size: 12px;
      color: $translation_missing;
    }

    .attribute {
      font-size: 10px;
    }
  }

  .translated {
    display: flex;
    padding: 8px 0px;

    &:hover {
      background: $light_gray_2;
      cursor: pointer;
    }

    .check {
      padding: 8px 7px;
      font-size: 9px;
    }

    .text {
      width: 200px;
    }

    .to_text {
      font-size: 12px;
    }

    .from_text {
      font-size: 10px;
    }
  }
}
