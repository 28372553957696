@import "variables.module.scss";

.btn {
  display: inline-block;
  vertical-align: top;

  font-weight: 400;
  font-size: 13px;

  cursor: pointer;

  border-radius: 4px;
  background: $button_dark;
  background-image: linear-gradient(to bottom, $button_light, $button_dark);
  color: #ffffff;
  padding: 8px 10px 8px 10px;
  text-decoration: none;
  user-select: none;

  border: 1px solid rgba(90, 90, 90, 0.1);

  &.disabled {
    background: $button_disabled;
    text-decoration: none;
    color: darken($button_disabled, 5%) !important;
    cursor: default;

    &:hover {
      background: $button_disabled;
    }
  }

  &:hover {
    background: $button_hover;
    background-image: linear-gradient(to bottom, $button_hover, $button_light);
    text-decoration: none;
  }

  &.primary {
    color: #fff;

    &.active {
      background: darken($button_dark, 2%) !important;
    }
  }

  &.secondary {
    color: #666;
    background: none;
    border: 1px solid transparent;

    &.active {
      background: darken($light_gray, 2%) !important;
    }

    &:hover {
      border: 1px solid darken($light_gray, 5%);
    }
  }

  &.tertiary {
    color: #666;
    border: 1px solid darken($light_gray, 5%);
    background: none;

    &.active {
      background: darken($light_gray, 2%) !important;
    }

    &:hover {
      background: lighten ($light_gray, 5%);
    }
  }
}