@import 'variables.module.scss';

.delimited {
  display: flex;
  align-items: baseline;
}

.non_delimited {
  display: flex;
  align-items: baseline;
}

.lead_in {
}

.lead_in_sub {
  margin-left: 8px;
}

.result_select {
  width: 254px;
  margin-left: 10px;
}

.result_select_delimited {
  width: 166px;
  margin-left: 10px;
}

.should_delimit {
  margin-top: 20px;
}

.field_number {
  width: 96px;
  margin-left: 10px;
}

.delimiter {
  width: 158px;
  margin-left: 12px;
}
