@import 'variables.module.scss';

.options {
  display: flex;
  align-items: center;
}

.lead_in {
}

.bucket_select {
  width: 154px;
  margin-left: 10px;
  margin-top: -5px;
}

$bucketSpacing: 6px;

.bucket_list {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  margin: 0px -$bucketSpacing;
  margin-top: 5px;
}

.bucket_name {
  flex: 1 1 120px;
  margin: $bucketSpacing;
}
