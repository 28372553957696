@import "variables.module.scss";

.pill {
  font-size: 10px;
  padding: 3px 8px;
  line-height: 10px;
  background: $light_gray_2;
  display: flex;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid darken($light_gray_2, 5%);

  &.clickable:hover {
    background: darken($light_gray_2, 5%);
  }
}

.large {
  font-size: 12px;
  line-height: 12px;
  padding: 4px 8px;
}