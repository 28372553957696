@import "variables.module.scss";

.errors {
  color: $red;
  text-align: center;
  .error {
    margin-top: 16px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid $red;
  }
}
