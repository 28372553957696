@import 'variables.module.scss';

$curvature: 10px;

.clickable {
  cursor: pointer;
}

.dialog {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 5px 10px 1px #555;
  width: 655px;

  .header {
    color: #fff;

    border-top-left-radius: $curvature;
    border-top-right-radius: $curvature;

    .header_icon {
      font-size: 80px;
      position: absolute;
      left: -35px;
      top: 16px;
      clip: rect(0px, 100px, 54px, 35px);
    }

    .title {
      font-size: 20px;
      padding: 16px;
    }

    &.barber {
      $color_1: rgba(0, 0, 0, 0.08);
      $color_2: rgba(0, 0, 0, 0.05);
      background-image: repeating-linear-gradient(60deg,
          $color_1,
          $color_1 12px,
          $color_2 12px,
          $color_2 24px);
    }

    &.iconed {
      .title_container {
        margin-left: 50px;
      }
    }

    .title_container {
      display: flex;
      align-items: flex-end;
    }
  }

  .content {
    padding: 10px 20px;
    margin-bottom: 10px;

    p {
      margin-top: 10px;
      line-height: 20px;
    }
  }

  .footer {
    padding: 12px;
    background: $light_gray;
    border-bottom-left-radius: $curvature;
    border-bottom-right-radius: $curvature;

    justify-content: space-between;
    align-items: center;

    .gutter {
      vertical-align: baseline;
      margin-right: auto;
    }

    .buttons {
      display: flex;
    }

    .left_buttons {
      margin-right: auto;
    }

    .right_buttons {
      margin-left: auto;
      text-align: right;
      vertical-align: baseline;
    }
  }

  .tabs {
    z-index: $z_modal_tabs;
    padding-right: 18px;
    display: flex;
    align-items: stretch;
    flex-direction: row-reverse;
    margin-top: 0px;

    .tab {

      background: rgba(0, 0, 0, 0.1);
      padding: 5px 10px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      align-items: center;

      margin-left: 7px;
      font-size: 11px;
      cursor: pointer;
      color: #fff;

      &:hover {
        background: #00000020;
      }

      &.active {
        // border: 3px solid #00000011;
        // border-bottom: none;
        background: #fff;
        cursor: default;
        color: #000;

        &:hover {
          background: #fff;
        }
      }
    }
  }
}