@import "variables.module.scss";

.text {
  margin-right: 10px;
}
.recording {
  position: absolute;
  right: 0px;
  bottom: 10px;
}
