@import 'variables.module.scss';

@mixin color-choice($color) {
  &.color_option {
    background: $color;
  }

  .sticky {
    background: $color;
    box-shadow: 5px 5px 0px -2px rgba(darken($color, 10%), 0.3);
  }
}

.yellow {
  @include color-choice($sticky_yellow);
}

.blue {
  @include color-choice($sticky_blue);
}

.purple {
  @include color-choice($sticky_purple);
}

.gray {
  @include color-choice($sticky_gray);
}

.green {
  @include color-choice($sticky_green);
}

.color_chooser_container {
  height: 17px;
  width: 100%;
  overflow: hidden;

  &:hover {
    .color_chooser {
      opacity: 1;
      left: 0px;
    }
  }

  .color_chooser {
    color: #fff;
    opacity: 0;
    transition: all 150ms ease-in-out;
    left: -30px;
    position: relative;
    display: inline-block;

    .color_option {
      height: 12px;
      width: 12px;
      border: 1px inset rgba(0, 0, 0, 0.2);
      display: inline-block;
      margin: 2px 3px;

      &:hover {
        //margin-top: 0px;
        margin-top: 1px;
        margin-bottom: 1px;
        margin-right: 2px;
        margin-left: 2px;
        border: 1px inset rgba(0, 0, 0, 0.3);
        height: 14px;
        width: 14px;
      }
    }
  }
}

.sticky_container {
  position: relative;
  padding: $node_padding + px;
  z-index: $z_sticky;

  textarea::selection {
    background: rgba(0, 0, 0, 0.1);
  }

  .sticky {
    font-size: 13px;
    width: 176px;
    cursor: pointer;
    // transition: all 300ms linear;

    &.selected {
      box-shadow: 0px 0px 0px 2px $selected !important;
      border: 0px solid $selected;
    }

    .removal.title_wrapper {
      transition: all 100ms ease-in-out;
      background: transparent;

      .remove_button {
        color: $removal_confirmation;
      }

      textarea.title {
        visibility: hidden;
      }

      .confirmation {
        display: inline-block;
      }
    }

    .title_wrapper {
      transition: none;
      padding: 8px 8px;
      padding-right: 12px;
      padding-bottom: 4px;
      width: 156px;
      background: rgba(0, 0, 0, 0.05);

      position: relative;

      &:hover {
        .remove_button {
          visibility: visible;
        }
      }

      .remove_button {
        position: absolute;
        right: 8px;
        top: 10px;
        color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        visibility: hidden;
        font-size: 14px;
      }

      .confirmation {
        margin: -8px;
        padding: 8px;
        color: $removal_confirmation;
        width: 160px;
        text-align: center;
        font-size: 13px;
        font-weight: 400;
        display: none;
        position: absolute;
      }

      textarea.title {
        transition: none;
        border: 0px solid grey;
        background: none;
        font-weight: 500;
        font-size: 12px;
        font-family: 'Roboto', sans-serif;
        line-height: 14px;
        width: 140px;
        resize: none !important;
        outline: none;
        box-shadow: none;
        font-size: 12px;
        color: darken($text_color, 5%);
        height: inherit;
        cursor: pointer;
      }
    }

    .body_wrapper {
      padding: 8px 8px;
      width: 160px;

      textarea.body {
        font-family: 'Roboto', sans-serif;
        outline: none;
        box-shadow: none;
        border: none;
        background: none;
        resize: none !important;
        color: $text_color;
        font-size: 12px;
        width: 154px;
        height: inherit;
        cursor: pointer;
      }
    }
  }
}