$yellow: #f1c40f;
$orange: #e68628;
$green: #309c42;
$teal: #01c1af;
$purple: #8e5ea7;
$blue: #3498db;
$gray: #666;
$pink: #df419f;
$red: tomato;
$black: #444;

$dark_teal: #1a777c;
$lime: #8ad164;
$emerald: #357200;
$brown: #897644;

$dark_blue: #4d7dad;
$blue_gray: #758590;
