@import "variables.module.scss";

.counter {
  z-index: $z_counters;
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.clickable {
  cursor: pointer;
}
