@import 'variables.module.scss';

$reset_color: #666;

.sim_container {
  position: relative;
  right: 0px;
  z-index: $z_simulator;
}

.simulator.sim_hidden {
  left: 0px !important;
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.075);
  transition-delay: 100ms;
}

.simulator {
  background: $black;
  border-radius: 12px;

  img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    border: 0;
  }

  input {
    &:focus {
      outline-color: transparent;
      outline-style: none;
    }

    &::-webkit-input-placeholder {
      color: $placeholder_color;
    }

    &::-moz-placeholder {
      color: $placeholder_color;
    }

    &:-ms-input-placeholder {
      color: $placeholder_color;
    }

    &:-moz-placeholder {
      color: $placeholder_color;
    }
  }

  input:disabled {
    background: lighten(#e6e6e6, 5%);
    cursor: not-allowed;
    $disabled_color: darken($placeholder_color, 5%);

    &::-webkit-input-placeholder {
      color: $disabled_color;
    }

    &::-moz-placeholder {
      color: $disabled_color;
    }

    &:-ms-input-placeholder {
      color: $disabled_color;
    }

    &:-moz-placeholder {
      color: $disabled_color;
    }
  }

  height: 500px;
  width: 260px;
  cursor: pointer;
  z-index: $z_simulator;
  transition: right 400ms cubic-bezier(0.175, 0.885, 0.32, 1.075);
  transition-delay: 100ms;

  .screen {
    top: 6px;
    height: 488px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin: 0px 5px;
    cursor: default;

    .header {
      color: #eee;
      background: #555;
      height: 32px;

      .close {
        font-size: 20px;
        width: 25px;
        height: 25px;
        right: 5px;
        top: 5px;
        position: absolute;
        color: #ddd;
        cursor: pointer;

        &:hover {
          color: #fff;
        }
      }

      .time {
        display: inline-block;
        visibility: hidden;
      }

      .battery {
        display: inline-block;
        visibility: hidden;
        right: 12px;
        position: absolute;
        font-size: 13px;
      }

      .wifi {
        visibility: hidden;
        display: inline-block;
        right: 38px;
        position: absolute;
      }
    }

    .footer {
      color: #eee;
      background: #555;
      height: 35px;
      position: absolute;
      z-index: 200;
      bottom: 0;
      width: 100%;

      $light_reset: lighten($reset_color, 7%);

      .show_context_button {
        display: inline-block;
        padding: 12px;
        cursor: pointer;

        &:hover {
          color: #ddd;
        }
      }

      .reset {
        background-color: $reset_color;
        border: 2px solid lighten($reset_color, 7%);
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 16px;
        cursor: pointer;
        width: 60px;
        height: 15px;
        position: absolute;
        left: 94px;
        margin: 8px 0px;

        &:hover {
          background-color: $light_reset;
          border: 2px solid lighten($light_reset, 5%);
        }

        .inactive {
          background-color: $reset_color;
          border: 2px solid $light_reset;
        }

        .active {
          background-color: $reset_color;
          border: 2px solid $light_reset;
        }
      }
    }

    .messages {
      background-color: #fff;
      user-select: none;
      padding: 10px;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
      transition: height 0.3s linear;
      box-sizing: content-box;
    }

    .controls {
      z-index: 100;
      background: #444;
      bottom: 0px;
      width: 240px;
      padding: 5px 5px;
      bottom: 35px;
      position: absolute;

      input {
        &:focus {
          outline: none;
          box-shadow: none;
          border: none;
        }

        box-sizing: content-box !important;
        border-radius: 4px;
        border: none;
        outline: none;
        box-shadow: none;
        font-size: 12px !important;
        height: 13px;
        width: 195px;
        padding: 6px 8px;
        padding-right: 36px;
        color: #333;
        display: inline-block;
        margin: 0;
      }
    }

    .show_attachments_button {
      display: inline-block;
      vertical-align: sub;
      padding: 0px;
      position: absolute;
      top: 11px;
      right: 2px;
      cursor: pointer;


      transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.075);

      &:hover {
        transform: scale(1.1);
        color: #333;
      }
    }

    .attachment_buttons {
      bottom: -100px;
      position: absolute;
      transition: bottom 200ms cubic-bezier(0.175, 0.885, 0.32, 1.075);
      width: 100%;
      background: #666;
      color: #e6e6e6;
      z-index: 100;
      text-align: center;
      display: flex;

      &.visible {
        bottom: 40px;
      }

      temba-icon {
        padding: 0.6em;
        border-radius: var(--curvature);
        margin: 0.4em;
      }

      temba-icon:hover {
        background: #888;
        cursor: pointer;
      }
    }
  }
}

.drawer {
  &.drawer_visible {
    &.forced {
      background: #666;
    }
  }

  width: 100%;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 50;
  background: #555;
  transition: bottom 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  padding-bottom: 30px;

  .drawer_header {
    height: 10px;
    background: #555;
    color: $light_gray;
    width: 100%;
  }

  .map_thumb {
    height: 74px;
    width: 100%;
    cursor: pointer;
  }

  .audio_picker {
    padding: 20px;
    color: $light_gray;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;

    &:hover {
      background: #777;
    }

    .audio_message {
      font-size: 15px;
      padding-left: 3px;
    }
  }

  .keypad {
    .keypad_entry {
      background: #555;
      color: $light_gray;
      font-size: 18px;
      padding: 5px;
      text-align: center;
      letter-spacing: 4px;
    }

    .keys {
      color: $light_gray;
      padding: 5px;

      .row {
        width: 100%;
        margin: 0;
        text-align: center;

        .key {
          padding: 4px 13px;
          background: #777;
          border-radius: 6px;
          font-weight: bold;
          border: 1px solid $light_gray;
          display: inline-block;
          text-align: center;
          margin: 2px;
          font-size: 14px;
          cursor: pointer;

          &:hover {
            background: #999;
          }
        }
      }
    }
  }

  .drawer_items {
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    padding-right: 10px;

    .drawer_item {
      width: 70px;
      padding-left: 10px;
      flex: 1 1 auto;
      cursor: pointer;
      transition: transform 0.1s linear;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}

.tab_hidden {
  display: none;
}

.simulator_tab.tab_hidden,
.simulator_tab:hover.tab_hidden {
  right: -130px;
  transition: right 100ms;
}

.simulator_tab {
  background-color: #2db379;
  width: 130px;
  height: 50px;
  position: absolute;
  right: -77px;
  top: 220px;
  cursor: pointer;
  z-index: $z_simulator;
  transition: right 200ms, opacity 300ms;
  transition-delay: 300ms;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;

  .simulator_tab_icon {
    position: absolute;
    font-size: 34px;
    color: white;
    padding: 8px 0px;
    margin-left: 3px;
  }

  .simulator_tab_text {
    position: absolute;
    left: 40px;
    top: 10px;
    color: white;
    opacity: 0.01;
    font-weight: 400;
    transition: opacity 500ms;
    transition-delay: 1000ms;
  }
}

.simulator_tab:hover {
  right: -10px;
  transition: right 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

  .simulator_tab_text {
    opacity: 0.99;
    transition: opacity 500ms;
  }
}

.detail_webhook_called {
  background: $orange;
}

.quick_replies {
  text-align: center;
  padding: 5px;

  .quick_reply {
    // white-space: nowrap;
    display: inline-block;
    color: $light_gray;
    text-align: center;
    padding: 5px 16px;
    border: 1px solid #666;
    margin: 3px 6px;
    border-radius: 14px;
    cursor: pointer;
    background: #444;

    transition: transform 0.1s linear;

    &:hover {
      background: #333;
      transform: scale(1.05);
    }
  }
}