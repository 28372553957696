@import 'variables.module.scss';

.translating_categories {
  clear: both;
}

.translating_category {
  margin-bottom: 3px;
}

.translating_from {
  background: $translation_bg;
  border-radius: 5px;
  padding: 0px 9px;
  border: 1px solid darken($translation_bg, 5%);
  display: inline-block;
  width:  35%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  line-height: 36px;
}

.translating_to {
  display: inline-block;
  width: 59%;
  vertical-align: top;
  float: right;  
}

.translating_operator_title {
  font-weight: 400;
  font-size: 18px;
  margin: 0 9px 15px;
}

.translating_operator_instructions {
  margin: 0 10px 15px;
}

.translating_operator_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 10px;
}

.translating_operator {
  overflow: hidden;
  width: 145px;
  font-style: italic;
  padding-top: 10px;
  padding-left: 10px;
}

.translating_list_container {
  overflow: hidden;
  border-radius: $curvature;
  margin-top: 8px;
  &:focus {
    outline: none;
  }

  &.scrolling {
    margin-top: 5px;
    background: rgb(250, 250, 250);
  }

  .translating_item_list {
    position: relative;
    overflow-y: auto;
    max-height: 200px;
    padding: 10px;
  }
}