@import 'variables.module.scss';

$spacing: 10px;

.group {
  padding-bottom: 0 !important;
  margin-bottom: 0px;
}

textarea:focus,
input:focus {
  outline: none;
}

.divider {
  padding-left: $spacing;
  padding-right: $spacing;
  vertical-align: sub;
  font-size: 13px;
  line-height: 13px;
  margin-top: 4px;
}

.kase.has_intent,
.kase.has_top_intent {
  .choice {
    width: 150px;
  }

  .multi_operand {
    width: 202px;
  }
}

.kase {
  display: inline-flex;
  align-items: center;
  user-select: none;
  margin-bottom: 5px;

  .form_group {
    margin: 0;
  }

  .choice {
    width: 185px;
  }

  .single_operand {
    display: inline-flex;
    align-items: center;
    position: relative;
    margin-left: $spacing;

    width: 184px;

    input {
      width: 170px;
    }
  }

  .multi_operand {
    display: inline-flex;
    align-items: center;
    margin-left: $spacing;
    width: 184px;

    input[name='intent'] {
      width: 105px;
    }

    input[name='confidence'] {
      // width: 40px;
      padding: 10px 6px !important;
    }
  }

  .categorize_as {
    font-size: 13px;
    line-height: 13px;
    position: relative;
    width: 95px;
    text-align: center;
    margin: 0px $spacing - 2;
    margin-top: 4px;
  }

  .category {
    position: relative;
  }

  &:hover {
    .remove_icon {
      opacity: 1;
    }

    .dnd_icon {
      opacity: 1;
    }
  }

  input {
    padding: 6px 7px;
    height: 29px !important;
    margin-bottom: -4px;
    font-size: 13px !important;
  }

  .relative_date {
    display: inline-block;
    width: 50px;

    input {
      width: 50px;
    }
  }
}

.dnd_icon {
  color: #aaa;
  font-weight: bold;
  padding-right: 2px;
  padding-left: 5px;
  opacity: 0;
}

.remove_icon {
  color: #aaa;
  float: right;
  opacity: 0;
  cursor: pointer;
  margin-left: 2px;
  z-index: 3000;
}

.empty {
  display: inline-block;
  width: 15px;
}

[data-draggable='true'] {
  cursor: pointer;
}