@import 'variables.module.scss';

.lead_in {
  margin-bottom: 5px;
}

.field_select {
  width: 263px;
  display: inline-block;
  margin-left: 10px;
  margin-bottom: -10px;
}
