@import 'mixins.module.scss';

.timeout_control_container {
  display: flex;
  font-size: 13px;
  vertical-align: middle;
  --checkbox-padding: 5px 10px;
}

.left_section {
  font-size: 13px;
  vertical-align: middle;
}

.label {
  cursor: pointer;
}

.checkbox {
  background-color: white;
}

.drop_down {
  width: 160px;
  margin-left: 10px;
  vertical-align: bottom;
}