@import 'variables.module.scss';

.quick_replies {
  display: inline-block;
  color: $gray;
}

.attachment {
  padding-left: 5px;
  color: $gray;
  transform: rotate(30deg);
  display: inline-block;
  padding-right: 8px;
}

.summary {
  margin-top: 5px;
}

.whatsapp {
  display: inline-block;
  padding-left: 5px;
  color: $gray;
}

.facebook {
  display: inline-block;
  padding-left: 5px;
  color: $gray;
}

.line {
  display: block;
  margin-top: 3px;
  min-height: 11px;
}