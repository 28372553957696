@import 'variables.module.scss';

.message {
  color: lighten($gray, 7%);
  font-size: 12px;
  border: 1px solid var(--color-widget-border);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: none;
  padding: 3px 10px 5px 10px;
  background: #fff;
  margin-top: -5px;
  padding-top: 5px;
  z-index: 1000;
  position: relative;
}
